import Axios from "@/plugins/Axios";
import EducacaoInclusiva from "@/Models/EducacaoInclusiva";

export class EducacaoInclusivaService {
  async syncAll(form) {
    const response = await Axios().post(`educacao-inclusiva`, form);
    const payload = response.data;
    return new EducacaoInclusiva(payload);
  }

  async syncAllPaginate(pageNumber, query) {
    const response = await Axios().get(
      `educacao-inclusiva/listall/paginate?page=${pageNumber}&query=${query}`
    );
    return response.data;
  }

  async lancar(form) {
    await Axios().post(`educacao-inclusiva/salvar-relatorio`, form);
  }
}
export default new EducacaoInclusivaService();
