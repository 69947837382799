<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'usuarios' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Usuário
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(submitForm)"
        autocomplete="off"
        :disabled="submittingForm"
      >
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Nome Completo" rules="required" v-slot="{ errors }">
              <e-label>Nome Completo</e-label>
              <v-text-field :error-messages="errors" v-model="form.name" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Login de acesso" rules="required" v-slot="{ errors }">
              <e-label>Login de acesso</e-label>
              <v-text-field :error-messages="errors" v-model="form.email" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6" v-if="!editing">
            <ValidationProvider
              name="Senha"
              :rules="editing ? 'min:4|confirmed:password' : 'required|min:4'"
              v-slot="{ errors }"
              vid="password"
            >
              <e-label>Senha</e-label>
              <v-text-field
                :error-messages="errors"
                type="password"
                autocomplete="off"
                v-model="form.password"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6" v-if="!editing">
            <ValidationProvider
              name="Confirme a senha"
              :rules="editing ? 'min:4|confirmed:password' : 'required|min:4|confirmed:password'"
              v-slot="{ errors }"
            >
              <e-label>Confirme a senha</e-label>
              <v-text-field
                :error-messages="errors"
                type="password"
                v-model="form.password_confirm"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
              <e-label>Tipo</e-label>
              <e-autocomplete
                :items="outrosRolesList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(value) => value.name"
                v-model="form.roles[0].id"
                @change="(value) => setRole(value)"
                label="Selecione uma opção"
                dense
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <!-- v-model="form.roles[0].id" -->

          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Franquia(s) do usuário:</e-label>
            <e-autocomplete
              :items="franquiasList"
              :return-object="false"
              v-model="form.franquias_permitidas"
              :item-value="(franquia) => franquia.id"
              :item-text="(franquia) => franquia.descricao"
              label="Selecione uma opção"
              dense
              multiple
              solo
            />
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";
import rolesService from "../../Services/RolesService";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      submittingForm: false,
      form: { roles: [{}] },
      edit: false,
      roles: [{}],
      outrosRolesList: [],
      rolesList: [],
      usuarios: [],
      carregandoUsuarios: false,
      usuariosLoaded: false,
      usuariosList: [],
      outrosUsuariosList: [],
      franquiasList: [],
    };
  },
  watch: {
    form: {
      async handler(formValue) {
        try {
          const cepValue = this.$services.cepService.getCepInteiro(formValue.cep);
          if (cepValue) {
            const localizacao = await this.$services.cepService.buscarDadosDoCep(cepValue);
            this.form.municipio_residencia = localizacao.cidade;
            this.form.uf_residencia = localizacao.estado;
          }
        } catch (error) {
          this.$handleError("Erro ao buscar informações do CEP");
        }
      },
      deep: true,
    },
  },
  methods: {
    async listarFranquias() {
      try {
        this.franquiasList = await this.$services.franquiasService.syncAll();
      } catch (error) {
        this.$handleError(error);
      }
    },
    setRole(item) {
      this.form.roles[0] = { id: item };
    },
    async carregarRoles() {
      const response = await rolesService.syncAll();
      const outrosRolesList = response.roles.filter(
        (role) =>
          !this.$constants.rolesParaProfessores.map((role) => role.id).includes(role.id) &&
          role.name !== "aluno"
      );
      this.outrosRolesList = outrosRolesList;
    },
    async pegarUsuario() {
      try {
        const { usuario_id } = this.$route.params;
        const response = await this.$services.usuariosService.getUsuario(usuario_id);
        return response;
      } catch (error) {
        return this.$handleError(error);
      }
    },
    async submitForm() {
      if (!this.editing && this.form.password !== this.form.password_confirm) {
        this.$toast.warn("As senhas não estão iguais");
        return;
      }
      this.form.especial = Boolean(this.form.especial);
      this.submittingForm = true;
      if (!this.form.password) delete this.form.password;
      try {
        if (this.editing) {
          await this.$services.usuariosService.atualizarUsuario(this.form);
        } else {
          await this.$services.usuariosService.criarUsuario(this.form);
        }
        this.$toast.success("Usuario Atualizada com sucesso");
        this.$router.push({ name: "usuarios" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async loadData() {
      try {
        if (
          this.usuarios.length === 0 ||
          this.rolesList.length === 0 ||
          this.franquiasList.length === 0
        ) {
          this.$loaderService.open("Carregando dados");
        }
        await Promise.all([this.carregarRoles(), this.listarFranquias()]);
        this.$loaderService.close();
        this.$forceUpdate();
        this.loadFormData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async loadFormData() {
      if (!this.usuarios || !this.editing) {
        return;
      }
      // const { usuario_id } = this.$route.params;
      // const usuario = this.usuarios.get(parseInt(usuario_id, 10));
      const usuario = await this.pegarUsuario();
      if (!usuario || this.form.id) return;
      this.form = {
        ...usuario,
      };
    },
  },
};
</script>

<style></style>
