import Axios from "@/plugins/Axios";

export class ObjetivosAprendizagem {
  async pesquisar(query) {
    const response = await Axios().get(
      `planos-bimestrais-infantil/objetivos-aprendizagem?query=${query}`
    );
    return response;
  }

  async pesquisarSaeb(query) {
    const { disciplina_id, turma_id, search } = query;
    const response = await Axios().get(
      `planos-bimestrais/saeb-aprendizagem?disciplina_id=${disciplina_id}&turma_id=${turma_id}&search=${search}`
    );
    return response;
  }
}

export default new ObjetivosAprendizagem();
