<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small @click="goBack" text>
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Educação Inclusiva
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="turma && aluno">
          <v-card-title>Relatório de acompanhamento individual</v-card-title>
          <v-card-text>
            <h4>Turma: {{ turma.descricao }}</h4>
            <h4>Aluno: {{ aluno.nomecompleto }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div v-for="diario in gestao_de_aulas" :key="diario.id">
              Professor(a): {{ diario.professor.nome }} | Disciplina:
              {{ diario.disciplina.descricao ? diario.disciplina.descricao : "" }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="12">
        <v-sheet cols="12" md="12" elevation="2" class="v-sheet--outlined" rounded>
          <v-lazy>
            <div class="py-2 px-4 text-subtitle-2">Painel de gestão</div>
          </v-lazy>
          <v-divider />
          <v-sheet class="py-2 px-4" md="12">
            <v-row v-if="loadBimestre">
              <v-col class="text-center pt-2" cols="12" md="12">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>
            <ValidationObserver v-else v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12">
                    <ValidationProvider name="Tipo de Aula" rules="required" v-slot="{ errors }">
                      <e-label>Tipo de Aula</e-label>
                      <e-autocomplete
                        :items="$constants.tiposDeAulas"
                        :return-object="false"
                        :error-messages="errors"
                        v-model="form.tipo_de_aula"
                        label="Selecione um tipo de aula"
                        dense
                        solo
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="mostrarCampos">
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="data"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <e-label>Selecione uma data</e-label>
                        <v-text-field
                          v-model="form.data"
                          persistent-hint
                          :disabled="form.tipo_de_aula ? false : true"
                          label="Escolha uma data"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          solo
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="data" range scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(data)"> OK </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="6">
                    <e-label>Escolha um bimestre</e-label>
                    <e-autocomplete
                      :items="$constants.bimestres"
                      :return-object="false"
                      :item-value="(value) => value.value"
                      :item-text="(value) => value.desc"
                      v-model="form.bimestre"
                      @change="onChangeBimestre"
                      label="Selecione uma opção"
                      dense
                      solo
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 pb-0" v-if="form.tipo_de_aula !== 'Aula Remota'" cols="6">
                    <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
                      <e-label>Selecione um horário</e-label>
                      <e-autocomplete
                        :disabled="form.data ? false : true"
                        :items="horarios"
                        :error-messages="errors"
                        label="Selecione uma opção"
                        :item-text="(item) => item.descricao"
                        v-model="horarioSelecionado"
                        @change="onChangeHorario"
                        return-object
                        dense
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="6" class="mt-3">
                    <v-alert
                      border="left"
                      color="orange"
                      class="animate__animated animate__headShake"
                    >
                      Você está editando o
                      <strong
                        >{{ $constants.bimestres[form.bimestre]?.desc }} do
                        {{ horarioSelecionado?.descricao }}</strong
                      >
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row v-if="form.bimestre != null && horarioSelecionado">
                  <v-col cols="12">
                    <e-label>Tipo de atendimento realizado </e-label>
                  </v-col>
                  <v-row>
                    <v-col
                      class="py-0 px-5"
                      cols="4"
                      v-for="(el, index) of $constants.tipoDeAtendimentoRealizado"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="form.tipo_atendimento_realizado[index]"
                        :label="el"
                        :value="el"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row v-if="form.bimestre != null && horarioSelecionado">
                  <v-col class="d-none">
                    <div v-for="diario in gestao_de_aulas" :key="diario.id">
                      <v-text-field
                        :v-model="
                          (form.disciplina_id = diario.disciplina.id ? diario.disciplina.id : null)
                        "
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col
                    v-for="(el, index) of $constants.aspectosObservados"
                    :key="index"
                    cols="12"
                  >
                    <ValidationProvider :name="el.descricao" rules="required" v-slot="{ errors }">
                      <e-label>{{ el.descricao }}</e-label>
                      <v-textarea
                        rows="3"
                        solo
                        dense
                        v-model="form[el.form]"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-col cols="12 mb-10">
                  <v-row>
                    <v-btn
                      color="primary"
                      :disabled="submittingForm || !horarioSelecionado"
                      :loading="submittingForm"
                      type="submit"
                      >Enviar Relatório</v-btn
                    >
                  </v-row>
                </v-col>
              </form>
            </ValidationObserver>
            <v-alert
              v-if="alert"
              text
              prominent
              type="error"
              class="mt-10 animate__animated animate__headShake"
              icon="mdi-cloud-alert"
            >
              Informe o bimestre
            </v-alert>
          </v-sheet>
          <div></div>
        </v-sheet>
      </v-col>
    </v-row>
  </main-template>
</template>
<script>
export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submittingForm: false,
      loadBimestre: false,
      alert: false,
      modal: false,
      form: {
        data: null,
        bimestre: null,
        horario_id: null,
        tipo_atendimento_realizado: [],
        matricula_id: parseInt(this.$route.params.matricula_id, 10),
        disciplina_id: null,
      },
      matricula: {},
      turma: {
        descricao: "",
      },
      aluno: {
        nomecompleto: "",
      },
      mostrarCampos: true,
      professor: {},
      diario: {},
      gestao_de_aulas: {
        diario: {
          professor: {
            nome: "",
          },
          disciplina: {
            descricao: "",
            id: null,
          },
        },
      },
      horarios: [],
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
      data: null,
    };
  },
  mounted() {
    this.loadBasicData(this.form.bimestre, this.form.horario_id);
  },
  watch: {
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
        new Date(value)
      );
      this.form.data = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    async carregarDadosPreenchidos(bimestre, horario_id) {
      try {
        const response = await this.$services.educacaoInclusivaService.syncAll({
          id: parseInt(this.$route.params.matricula_id, 10),
          bimestre: parseInt(bimestre, 10),
          horario_id: horario_id ?? null,
        });

        // Se não houver uma avaliação existente, limpe os campos do formulário
        if (!response.notas) {
          this.limparCamposFormulario();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    limparCamposFormulario() {
      this.form = {
        bimestre: this.form.bimestre, // Mantém o bimestre selecionado
        horario_id: this.form.horario_id, // Mantém o horário selecionado
        tipo_de_aula: this.form.tipo_de_aula,
        data: this.form.data,
        tipo_atendimento_realizado: [],
        matricula_id: parseInt(this.$route.params.matricula_id, 10),
        disciplina_id: null,
        // Outros campos do formulário
      };
    },

    async onChangeBimestre(bimestre) {
      try {
        const horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;
        const response = await this.$services.educacaoInclusivaService.syncAll({
          id: parseInt(this.$route.params.matricula_id, 10),
          bimestre: parseInt(bimestre, 10),
          horario_id: horario_id ?? null,
        });

        if (response.notas) {
          this.form = response.notas;
        } else {
          this.limparCamposFormulario();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async onChangeHorario(item) {
      const horario_id = item.id;
      try {
        const response = await this.$services.educacaoInclusivaService.syncAll({
          id: parseInt(this.$route.params.matricula_id, 10),
          bimestre: this.form.bimestre, // Use o bimestre atual
          horario_id: horario_id ?? null,
        });

        if (response.notas) {
          this.form = response.notas;
        } else {
          this.limparCamposFormulario();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async loadBasicData(bimestre, horario_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        const payload = await this.$services.educacaoInclusivaService.syncAll({
          id: parseInt(this.$route.params.matricula_id, 10),
          bimestre: parseInt(bimestre, 10),
          horario_id: horario_id ?? null,
        });
        if (payload.notas && payload.notas.matricula_id === this.form.matricula_id) {
          this.form = payload.notas
            ? payload.notas
            : {
                bimestre,
                tipo_atendimento_realizado: [],
                matricula_id: parseInt(this.$route.params.matricula_id, 10),
              };
        } else {
          // Se não houver avaliação associada à matricula_id, limpar os campos do formulário
          this.limparCamposFormulario();
        }
        this.gestao_de_aulas = payload.matricula.turma.gestao_de_aulas_a_e_e;
        this.horarios = payload.horarios;
        this.horarioSelecionado = payload.notas?.horario;
        this.matricula = payload.matricula;
        this.turma = payload.matricula.turma;
        this.aluno = payload.matricula.aluno;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async submitForm() {
      let response;

      try {
        if (this.form.bimestre >= 0) {
          this.alert = false;
          this.$toast.info("Salvando relatório...");
          this.form.data = this.form.data.split("/").reverse().join("-");
          this.form.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;
          response = await this.$services.educacaoInclusivaService.lancar(this.form);
          this.$toast.success("Relatório salvo com sucesso!");
          return response;
        }
        this.alert = true;
        return this.alert;
      } catch (error) {
        this.$handleError(error);
      }
      return response;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
