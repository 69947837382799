<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'gestoesDeAulas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Vínculo
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Professor" rules="required" v-slot="{ errors }">
              <!-- aqui -->
              <e-label>Professor</e-label>
              <e-autocomplete
                :items="professoresList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => `${val.cpf} - ${val.nome} - ${val.user.roles[0].name}`"
                :error-messages="errors"
                :loading="carregandoProfessores"
                v-model="form.instrutor_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-8 pb-0" cols="6"> </v-col>
        </v-row>
        <v-row
          class="animate__animated animate__zoomIn"
          style="animation-duration: 0.5s"
          v-for="relacao of relacoes"
          :key="relacoes.indexOf(relacao)"
        >
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Turma" rules="required" v-slot="{ errors }">
              <e-label>Turma</e-label>
              <e-autocomplete
                :items="turmasList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                :error-messages="relacao.errors ? relacao.errors : errors"
                :loading="carregandoTurmas"
                v-model="relacao.turma_id"
                :disabled="editing"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Disciplina" rules="required" v-slot="{ errors }">
              <e-label>Disciplina</e-label>
              <e-autocomplete
                :items="disciplinasList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                :error-messages="relacao.errors ? relacao.errors : errors"
                :loading="carregandoDisciplinas"
                v-model="relacao.disciplina_id"
                :disabled="editing"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="2">
            <e-label>Carga horária</e-label>
            <v-text-field v-model="relacao.carga_horaria" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="1">
            <v-btn
              v-if="relacoes.length > 1"
              class="mt-8"
              color="error"
              @click="() => (relacoes = relacoes.filter((relacaoObj) => relacaoObj !== relacao))"
              small
              :disabled="submittingForm"
              ><v-icon small>fa-trash</v-icon></v-btn
            >
            <v-checkbox
              class="ms-3 mt-8"
              v-model="relacao.is_hp"
              :value="parseInt(1)"
              label="HP"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="d-flex justify-space-between">
            <div class="d-flex mt-2">
              <v-btn
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn
                class="ml-3"
                v-if="!editing"
                color="secondary"
                @click="adicionarRelacao"
                :disabled="submittingForm"
              >
                Adicionar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      submittingForm: false,
      professoresList: [],
      turmasList: [],
      form: {},
      relacoes: [{}],
      disciplinasList: [],
      carregandoProfessores: false,
      carregandoTurmas: false,
      carregandoDisciplinas: false,
    };
  },
  methods: {
    adicionarRelacao() {
      const ultimaRelacao = this.relacoes[this.relacoes.length - 1];
      this.relacoes.push({ ...ultimaRelacao });
    },
    async submitForm() {
      this.submittingForm = true;
      try {
        if (this.editing) {
          const formVinculo = {
            ...this.relacoes[0],
            instrutor_id: this.form.instrutor_id,
          };
          await this.$services.gestoesDeAulasService.atualizar(formVinculo);
        } else {
          const formRelacoes = this.relacoes.map((relacao) => ({
            ...relacao,
            instrutor_id: this.form.instrutor_id,
          }));
          const responseData = await this.$services.gestoesDeAulasService.criar(formRelacoes);
          this.relacoes = responseData.filter((item) => item.errors);
        }
        this.$toast.success("Vínculos criados/atualizados com sucesso");
        if (this.relacoes.length === 0 || this.editing) {
          this.$router.push({ name: "gestoesDeAulas" });
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados");
        this.professoresList = await this.$services.professoresService.syncAll();
        this.turmasList = await this.$services.turmasService.syncAll();
        this.disciplinasList = await this.$services.disciplinasService.syncAll();
        if (this.editing) {
          const vinculo = await this.$services.gestoesDeAulasService.gestaoGeralId(
            parseInt(this.$route.params.gestaoDeAulasId, 10)
          );
          if (vinculo) {
            this.form.instrutor_id = vinculo.instrutor_id;
            this.relacoes = [vinculo];
          }
        }
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
