import Vue from "vue";

// eslint-disable-next-line
export function longDate(dateStr) {
  return new Intl.DateTimeFormat("pt-BR", { dateStyle: "long" }).format(new Date(dateStr));
}

export function dateFormat(dateStr) {
  return new Intl.DateTimeFormat("pt-BR").format(new Date(dateStr));
}

export function dateFormatHour(dateStr) {
  const timeOptions = {
    hourCycle: "h23",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return new Intl.DateTimeFormat("pt-BR", {
    timeZone: "America/Fortaleza",
    ...timeOptions,
  }).format(new Date(dateStr));
}

export function formatHour(hourStr) {
  // '00:00:00' => '00:00'
  if (hourStr === null) return "- - -";
  const hourSplit = hourStr.split(":");
  const hours = hourSplit[0];
  const minutes = hourSplit[1];
  return `${hours}:${minutes}`;
}

// export function formatOnlyDate(dateStr) {
//   console.log("Data que chegou: " + dateStr);

//   // Divide a string da data
//   let [day, month, year] = dateStr.split('/');

//   // Adiciona zero à esquerda para mês e dia, se necessário
//   month = month.padStart(2, '0');
//   day = day.padStart(2, '0');

//   // Formata a data como 'YYYY-MM-DD'
//   let dataFormatada = `${year}-${month}-${day}`;

//   console.log("Data formatada: " + dataFormatada);

//   return dataFormatada;
// }

Vue.filter("longDate", longDate);
Vue.filter("dateFormat", dateFormat);
Vue.filter("hour", formatHour);
Vue.filter("dateFormatHour", dateFormatHour);
