import Axios from "@/plugins/Axios";

export class PlanosAnuaisService {
  async planoAnual(gestaoDeAulaId) {
    const response = await Axios().get(`planos-anuais/plano-anual/${gestaoDeAulaId}`);
    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post(`planos-anuais/criar-plano-anual`, {
      plano,
    });
    return response.data;
  }
}
export default new PlanosAnuaisService();
