import Model from "./Model";
import { dateFormat } from "../plugins/filters";

export default class EducacaoInclusiva extends Model {
  constructor(form) {
    super();
    Object.assign(this, form);
    if (this.notas) {
      this.notas.data = this.getDataFormatada();
    }
  }

  getDataFormatada() {
    return this.notas && this.notas.data_aula_lancada
      ? dateFormat(this.notas.data_aula_lancada)
      : null;
  }
}
