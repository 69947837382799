<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'aulas',
                  params: { gestaoDeAulaId: $route.params.gestaoDeAulaId },
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aula
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green" class="mb-5" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
            </h4>
            <h4>
              Turno:
              {{
                gestaoDeAula.turma.turno ? gestaoDeAula.turma.turno.descricao : "[NÃO INFORMADO]"
              }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Tipo de Aula" rules="required" v-slot="{ errors }">
              <e-label>Tipo de aula</e-label>
              <e-autocomplete
                :items="$constants.tiposDeAulas"
                :return-object="false"
                :error-messages="errors"
                v-model="aulaSelecionada.tipo_de_aula"
                @change="() => verificaTipoDaAula()"
                label="Selecione um tipo de aula"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row v-if="mostrarCampos">
          <v-col class="pt-0 pb-0" cols="4">
            <v-dialog ref="dialog" v-model="modal" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <e-label>Selecione uma data</e-label>
                <v-text-field
                  v-model="aulaSelecionada.data"
                  persistent-hint
                  :disabled="aulaSelecionada.tipo_de_aula ? false : true"
                  label="Escolha uma data"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker color="amber darken-3" v-model="data" :min="data_bloqueado" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="green darken-2" @click="modal = false"> Cancel </v-btn>
                <v-btn
                  text
                  color="green darken-2"
                  @click="
                    $refs.dialog.save(
                      (data = data
                        ? data
                        : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                            .toISOString()
                            .substr(0, 10))
                    )
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col class="pt-0 pb-0" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'" cols="4">
            <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
              <e-label>Selecione um horário</e-label>
              <e-autocomplete
                :disabled="aulaSelecionada.data ? false : true"
                :items="horarios"
                :error-messages="errors"
                label="Selecione uma opção"
                :item-text="(item) => item.descricao"
                v-model="horarioSelecionado"
                return-object
                dense
              />
            </ValidationProvider>
            <!-- @change="() => pesquisaAulas()" -->
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Dia da semana</e-label>
            <v-text-field solo dense v-model="aulaSelecionada.dia_da_semana" :disabled="true">
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-dialog
              v-model="searchDialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mb-3 mt-5" color="amber darken-3" dark v-bind="attrs" v-on="on">
                  INSIRA O CÓDIGO
                </v-btn>
              </template>

              <v-card>
                <v-toolbar dark color="amber darken-3">
                  <v-btn icon dark @click="searchDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>PESQUISA</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-sheet color="white" elevation="1">
                        <div class="py-2 px-4" color="white">
                          <v-text-field
                            @blur="search.length === 0 ? (objetivosAprendizagem = []) : false"
                            v-model="search"
                            color="green darken-2"
                            autofocus
                            v-on:keyup="() => procurarHabilidade()"
                            label="DIGITE O CÓDIGO!"
                          ></v-text-field>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-sheet color="white" elevation="1" rounded>
                        <v-lazy>
                          <div class="text-start py-2 px-4 text-button green darken-2 white--text">
                            HABILIDADES ENCONTRADAS
                            <v-icon dark right>mdi-magnify</v-icon>
                          </div>
                        </v-lazy>
                        <v-divider class="theme--light"></v-divider>

                        <div class="py-2 px-4">
                          <v-skeleton-loader
                            v-if="carregandoHabilidades"
                            class="mx-auto"
                            max-width="auto"
                            type="list-item"
                          ></v-skeleton-loader>

                          <v-list-item
                            class="grey lighten-3 mb-3 pa-md-4"
                            v-for="m in objetivosAprendizagem"
                            :key="m.id"
                          >
                            <v-col cols="10" class="d-flex flex-column justify-start">
                              [ {{ m.apelido }} ] {{ m.descricao }}
                              <v-row>
                                <v-col cols="12">
                                  <v-chip class="mt-2" color="amber darken-3">
                                    {{ m.parent ? m.parent.descricao : "" }}
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="2" class="d-flex align-center justify-center">
                              <v-btn
                                @click="() => adicionarHabilidade(m)"
                                class="mx-2"
                                fab
                                dark
                                small
                                color="green darken-2"
                              >
                                <v-icon dark>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-list-item>
                        </div>
                      </v-sheet>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-sheet color="white" elevation="1">
                        <v-lazy>
                          <div class="text-start py-2 px-4 text-button green darken-2 white--text">
                            Habilidades selecionadas
                            <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                          </div>
                        </v-lazy>

                        <v-divider class="theme--light"></v-divider>

                        <div class="py-2 px-4">
                          <p v-if="objeto_aprendizagem.length === 0">
                            Nenhuma habilidade selecionada
                          </p>

                          <v-list-item
                            class="grey lighten-3 mb-3 pa-md-4"
                            v-for="(m, index) in objeto_aprendizagem"
                            :key="m.id"
                          >
                            <v-btn
                              @click="() => deletarHabilidade(index, m)"
                              class="mx-2"
                              fab
                              dark
                              small
                              color="red"
                            >
                              <v-icon dark>mdi-delete</v-icon>
                            </v-btn>

                            <v-col cols="10" class="d-flex flex-column justify-start">
                              [ {{ m.apelido }} ] {{ m.descricao }}
                              <v-row>
                                <v-col cols="12">
                                  <v-chip class="mt-2" color="amber darken-3">
                                    {{ m.parent ? m.parent.descricao : "" }}
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-list-item>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>

              <v-snackbar v-model="showMessage" color="success">
                <h4>{{ mensagem }}</h4>
                <template v-slot:action="{ attrs }">
                  <v-btn color="white" text v-bind="attrs" @click="showMessage = false"> x </v-btn>
                </template>
              </v-snackbar>
            </v-dialog>
          </v-col>

          <template v-if="objeto_aprendizagem.length > 0">
            <v-col class="pt-0 pb-1" cols="12">
              <h4>Habilidades selecionadas</h4>
            </v-col>
            <v-row>
              <v-col
                v-for="(habilidade, index) in objeto_aprendizagem"
                :key="index"
                cols="12"
                sm="6"
                md="4"
              >
                <v-card class="mx-3" max-width="400" color="green">
                  <v-card-title class="headline">
                    <span class="small-text">[ {{ habilidade.apelido }} ]</span>
                  </v-card-title>
                  <v-card-subtitle>
                    {{ processDescricao(habilidade.descricao) ?? "" }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-chip color="amber darken-3" dark small>
                      {{ habilidade.parent.descricao }}
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>

          <v-col class="pt-5 pb-0" cols="12">
            <v-card class="mb-5">
              <v-card-title>
                <h4>Campos de experiências</h4>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    sm="6"
                    md="4"
                    v-for="(el, index) of opcoes"
                    :key="index"
                  >
                    <v-checkbox
                      color="green darken-2"
                      v-model="campos_de_experiencias[index]"
                      :label="el"
                      :value="el"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col class="pt-0 pb-0" cols="12">
            <v-card class="mb-5">
              <v-card-title>
                <h4>Rotina Permanente</h4>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    sm="6"
                    md="4"
                    v-for="(el, index) of opcoesRotinaPermanente"
                    :key="index"
                  >
                    <v-checkbox
                      color="green darken-2"
                      v-model="rotina_permanente[index]"
                      :label="el"
                      :value="el"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            v-if="horarioSelecionado || aulaSelecionada.tipo_de_aula === 'Aula Remota' || editing"
          >
            <ValidationProvider name="conteudo" rules="required" v-slot="{ errors }">
              <e-label>Eixos temáticos (Conteúdos)</e-label>
              <v-textarea solo dense v-model="aulaSelecionada.eixos" :error-messages="errors" />
            </ValidationProvider>

            <ValidationProvider name="estrategias" rules="required" v-slot="{ errors }">
              <e-label>Estratégias (Sala/Casa)</e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.estrategias"
                :error-messages="errors"
              />
            </ValidationProvider>

            <!-- <ValidationProvider name="recursos" rules="required" v-slot="{ errors }">
              <e-label>Recursos</e-label>
              <v-textarea solo dense v-model="aulaSelecionada.recursos" :error-messages="errors" />
            </ValidationProvider> -->

            <!-- <ValidationProvider name="atividade_classe" rules="required" v-slot="{ errors }">
              <e-label>Atividade de classe</e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.atividade_classe"
                :error-messages="errors"
              />
            </ValidationProvider> -->

            <!-- <ValidationProvider name="atividade_casa" rules="required" v-slot="{ errors }">
              <e-label>Atividade de casa</e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.atividade_casa"
                :error-messages="errors"
              />
            </ValidationProvider> -->

            <!-- <e-label>Observações</e-label>
            <v-textarea solo dense v-model="aulaSelecionada.observacoes" /> -->

            <v-col class="" cols="12">
              <h4>Recursos</h4>
              <v-combobox
                v-model="recursos"
                :items="recursosDeAulas"
                label="Recursos"
                color="green darken-2"
                multiple
                dense
                chips
                solo
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="data.item"
                    close
                    @click:close="remove(data.item)"
                    color="amber darken-3"
                    dark
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>

            <v-col>
              <v-switch
                v-model="add_recursos"
                label="Adicionar mais recursos?"
                color="green darken-2"
                inset
              ></v-switch>
            </v-col>

            <v-col class="pt-0 pb-1" cols="12" v-if="add_recursos">
              <h4>Outros Recursos</h4>
              <v-text-field
                class="mt-1 mb-2"
                label="Digite mais recursos"
                v-model="novoRecurso"
                hide-details="auto"
                solo
                dense
              ></v-text-field>
              <v-btn color="amber darken-3" dark @click="adicionarNovoRecurso">
                Adicionar recurso
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="add_recursos">
              <v-card class="mb-7">
                <v-card-title>Recursos não Listados</v-card-title>
                <v-card-text>
                  <v-chip
                    v-for="(recurso, index) in novos_recursos"
                    :key="index"
                    close
                    @click:close="removerNovoRecurso(index)"
                    color="amber darken-3"
                    dark
                    class="ma-2"
                  >
                    {{ recurso }}
                  </v-chip>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- <v-row>
              <v-col class="pt-0 pb-3 mb-1 my-auto" cols="12">
                <h4>Recursos</h4>
                <v-combobox
                  class="custom-chips"
                  v-model="recursos"
                  :items="recursosDeAulas"
                  label="Recursos"
                  color="green darken-2"
                  multiple
                  dense
                  chips
                  solo
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="data.item"
                      close
                      @click:close="remove(data.item)"
                      color="amber darken-3"
                      dark
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-4 pb-0 mt-4 mb-4" cols="12">
                <v-switch
                  v-model="add_recursos"
                  label="Adicionar mais recursos?"
                  color="green darken-2"
                  inset
                ></v-switch>
              </v-col>
            </v-row>

            <v-row v-if="add_recursos">
              <v-col class="pt-0 pb-1" cols="12">
                <h4>Outros Recursos</h4>
                <v-text-field
                  class="mt-1 mb-2"
                  label="Digite mais recursos"
                  v-model="novoRecurso"
                  hide-details="auto"
                  solo
                  dense
                ></v-text-field>
                <v-btn
                  class="mt-1 mb-2"
                  color="amber darken-3"
                  dark
                  @click="adicionarNovoRecurso"
                >
                  Adicionar recurso
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-card class="mb-7">
                  <v-card-title>Recursos não Listados</v-card-title>
                  <v-card-text>
                    <v-chip
                      v-for="(recurso, index) in novos_recursos"
                      :key="index"
                      close
                      @click:close="removerNovoRecurso(index)"
                      color="amber darken-3"
                      dark
                      class="ma-2"
                    >
                      {{ recurso }}
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" md="4">
                <v-btn
                  block
                  large
                  color="primary"
                  :disabled="submittingForm"
                  :loading="submittingForm"
                  type="submit"
                >
                  Enviar Dados
                </v-btn>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                  block
                  large
                  v-if="editing"
                  class="mt-2 mt-md-0 ml-md-3"
                  color="success"
                  @click="dialog.duplicar = true"
                >
                  Duplicar Aula
                </v-btn>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                  block
                  large
                  v-if="editing"
                  class="mt-2 mt-md-0 ml-md-3"
                  color="success"
                  @click="dialog.exportar = true"
                >
                  Exportar Aula
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="!aulaSelecionada.tipo_de_aula || !aulaSelecionada.data || !horarioSelecionado">
          <v-col cols="12" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'">
            <v-alert type="warning" border="left">
              Por favor, preencha os campos obrigatórios!
            </v-alert>
          </v-col>
        </v-row>
      </form>

      <v-bottom-sheet v-model="aulasEmConflitos" scrollable>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Fechar
          </v-btn>

          <h2 class="mt-4">Aulas conflitadas</h2>

          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Professor</th>
                  <th class="text-left">Situação</th>
                  <th class="text-left">Data/Horario</th>
                  <th class="text-left">Turma</th>
                  <th class="text-left">Disciplina</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr v-for="item in aulasConflitadas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.professor.nome }}
                    <br />
                    <v-chip color="grey" dark x-small>
                      {{ item.turma.franquia.descricao }}
                    </v-chip>
                  </td>
                  <td>{{ item.situacao ? item.situacao : "Aguardando" }}</td>
                  <td>
                    <span>
                      {{ item.data.split("-").reverse().join("/") }}
                    </span>
                    - {{ item.horario.descricao }}
                  </td>
                  <td>{{ item.turma.descricao }}</td>
                  <td>{{ item.disciplina.descricao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </ValidationObserver>
    <e-modal-duplicar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula>
    <e-modal-exportar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.exportar"
      :form="{ ...aulaSelecionada }"
      @dialogChange="dialog.exportar = $event"
      :horarios="horarios"
    >
    </e-modal-exportar-aula>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: Number,
      require: true,
    },
  },
  computed: {
    tipo_de_aula() {
      return this.aulaSelecionada.tipo_de_aula;
    },
    ...mapGetters("Auth", ["user"]),
    data_bloqueado() {
      return this.adicionarUmDia(this.user?.data_bloqueio);
    },
  },
  mounted() {
    this.loadData();
    if (this.editing) {
      this.mostrarCampos = true;
      this.outrosCampos = true;
    }
  },
  data() {
    return {
      opcoes: [
        "O eu, o outro e o nós",
        "Corpo, gestos e movimentos",
        "Escuta, fala, pensamento e imaginação",
        "Traços, sons, cores e formas",
        "Espaço, tempo, quantidades, relações e transformações",
      ],
      opcoesRotinaPermanente: [
        "Acolhida",
        "Chamadinha",
        "Calendário",
        "Roda de Conversa",
        "Contação de História",
      ],
      data: null,
      modal: false,
      menu: false,
      aulasEmConflitos: false,
      aulasConflitadas: [],
      diasSemana: this.$constants.diasDaSemana,
      recursosDeAulas: this.$constants.recursosDeAulas,
      mostrarCampos: true,
      outrosCampos: false,
      submittingForm: false,
      form: {},
      campos_de_experiencias: [],
      rotina_permanente: [],
      recursos: [],
      aulaSelecionada: {
        data: null,
        dia_da_semana: "",
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      aulaDuplicada: {},
      gestaoDeAulas: null,
      dialog: {
        duplicar: false,
        exportar: false,
      },
      horarios: [],
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
      data_bloqueio: null,
      searchDialog: false,
      search: "",
      carregandoHabilidades: false,
      objetivosAprendizagem: [],
      objeto_aprendizagem: [],
      showMessage: false,
      mensagem: "",
      // recursosDeAulas: [],
      addMaisRecursos: false,
      adicionarMaisRecursos: false,
      add_recursos: false,
      novoRecurso: "",
      novos_recursos: [],
    };
  },
  watch: {
    tipo_de_aula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota") {
        this.aulaSelecionada.horario_inicial = null;
        this.aulaSelecionada.horario_final = null;
        this.horarioSelecionado = null;
      }
    },
    data(value) {
      const date = new Date(value);
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(date);
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
      this.diaDaSemana();
    },
  },
  methods: {
    adicionarNovoRecurso() {
      if (this.novoRecurso.trim()) {
        if (!this.novos_recursos) {
          this.novos_recursos = [];
        }
        this.novos_recursos.push(this.novoRecurso.trim());
        this.novoRecurso = "";
        this.mensagem = "Recurso já selecionada!";
      }
    },
    removerNovoRecurso(index) {
      this.novos_recursos.splice(index, 1);
    },
    remove(item) {
      const index = this.recursos.indexOf(item);
      if (index > -1) this.recursos.splice(index, 1);
    },
    processDescricao(descricao) {
      return descricao.replace("A CAMPO DE EXPERIÊNCIAS “", "").replace("”", "");
    },
    async pesquisaAulas() {
      const data_aula = this.aulaSelecionada.data;
      /* eslint-disable */
      const aula_id = this.$route.params.aula_id;
      const horario_id = this.horarioSelecionado.id;
      const turma_id = this.gestaoDeAula.turma.id;
      if (!data_aula || !this.horarioSelecionado || !this.aulaSelecionada)
        return;

      const response =
        await this.$services.aulasService.pesquisarAulasEmConflitos(
          data_aula,
          horario_id,
          turma_id,
          aula_id
        );

      if (response.data.length <= 0) {
        this.mostrarCampos = true;
        this.outrosCampos = true;
      }

      if (response.data.length > 0) {
        this.aulasEmConflitos = true;
        this.outrosCampos = false;
        this.aulasConflitadas = response.data;
      }
    },
    async verificaTipoDaAula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota" || this.editing) {
        this.getAula();
        this.mostrarCampos = true;
        this.outrosCampos = true;
        return;
      }
      this.mostrarCampos = true;
      this.outrosCampos = false;
    },
    async submitForm() {
      this.aulaSelecionada.e_aula_infantil = 1;
      this.aulaSelecionada.campos_de_experiencias = this.campos_de_experiencias;
      this.aulaSelecionada.rotina_permanente = this.rotina_permanente;
      this.aulaSelecionada.recursos = this.recursos;
      this.aulaSelecionada.horario_inicial = this.horarioSelecionado
        ? this.horarioSelecionado.inicio
        : null;
      this.aulaSelecionada.horario_final = this.horarioSelecionado
        ? this.horarioSelecionado.final
        : null;
      this.aulaSelecionada.horario_id = this.horarioSelecionado
        ? this.horarioSelecionado.id
        : null;

      this.aulaSelecionada.habilidades = this.objeto_aprendizagem.map(
        (habilidade) => ({
          apelido: habilidade.apelido,
          descricao: habilidade.descricao,
          parent_descricao: habilidade.parent.descricao,
        })
      );

      this.aulaSelecionada.novos_recursos = this.novos_recursos;
      this.aulaSelecionada.add_recursos = this.add_recursos;

      this.submittingForm = true;
      try {
        if (this.editing) {
          this.aulaSelecionada.gestao_de_aula = parseInt(
            this.$route.params.gestaoDeAulaId,
            10
          );
          this.aulaSelecionada.data = this.aulaSelecionada.data
            .split("/")
            .reverse()
            .join("-");
          await this.$services.aulasService.atualizar(this.aulaSelecionada);
          this.$toast.success("Aula editada com sucesso!");
        } else {
          this.aulaSelecionada.gestao_de_aula = parseInt(
            this.$route.params.gestaoDeAulaId,
            10
          );
          this.aulaSelecionada.disciplina_id = this.gestaoDeAula.disciplina_id;
          this.aulaSelecionada.instrutorDisciplinaTurma_id = parseInt(
            this.$route.params.gestaoDeAulaId,
            10
          );
          this.aulaSelecionada.turma_id = parseInt(
            this.gestaoDeAula.turma_id,
            10
          );
          this.aulaSelecionada.instrutor_id = parseInt(
            this.gestaoDeAula.instrutor_id,
            10
          );
          this.aulaSelecionada.data = this.aulaSelecionada.data
            .split("/")
            .reverse()
            .join("-");
          await this.$services.aulasService.criarAula(this.aulaSelecionada);
          this.$toast.success("Aula cadastrada com sucesso!");
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
      this.$router.push({
        name: "aulas",
      });
    },
    async getAula() {
      const { aula_id } = this.$route.params;
      const response =
        await this.$services.gestoesDeAulasService.listarAulasGestao(
          this.$route.params.gestaoDeAulaId
        );
      this.gestaoDeAula = response.payload.gestaoDeAula;
      this.horarios = response.payload.horarios;
      // this.horarios = await this.$services.horariosService.horariosAll();
      const aulaSelecionada = await this.$services.aulasService.getAula(
        parseInt(aula_id, 10)
      );
      this.horarioSelecionado = aulaSelecionada.horario ?? null;
      this.campos_de_experiencias = aulaSelecionada.campos_de_experiencias
        ? aulaSelecionada.campos_de_experiencias
        : [];

      this.rotina_permanente = aulaSelecionada.rotina_permanente
        ? aulaSelecionada.rotina_permanente
        : [];

      this.recursos = aulaSelecionada.recursos ? aulaSelecionada.recursos : [];
      this.add_recursos = aulaSelecionada.add_recursos;
      this.novos_recursos = aulaSelecionada.novos_recursos;

      this.objeto_aprendizagem = aulaSelecionada.habilidades
        ? aulaSelecionada.habilidades.map((habilidade) => ({
            apelido: habilidade.apelido,
            descricao: habilidade.descricao,
            parent: { descricao: habilidade.parent_descricao },
          }))
        : [];

      return aulaSelecionada;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados...");
        this.aulaSelecionada = await this.getAula();
        if (this.aulaSelecionada.data) {
          this.aulaSelecionada.data = this.aulaSelecionada.data
            .split("-")
            .reverse()
            .join("/");
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    diaDaSemana() {
      var data_do_usuario = this.aulaSelecionada.data;
      var data_do_usuario_formatada = data_do_usuario.replace(/\//g, "-");
      var array1 = data_do_usuario_formatada.split("-");
      var array2 = array1.reverse();
      var data_final = new Date(
        array2[0],
        parseInt(array2[1], 10) - 1,
        array2[2]
      );
      var dia = this.diasSemana[data_final.getDay()];
      this.aulaSelecionada.dia_da_semana = dia;
    },
    adicionarUmDia(dataString) {
      let data = new Date(dataString);
      data.setDate(data.getDate() + 2);

      let year = data.getFullYear();
      let month = ("0" + (data.getMonth() + 1)).slice(-2);
      let day = ("0" + data.getDate()).slice(-2);

      return `${year}-${month}-${day}`;
    },
    async procurarHabilidade() {
      this.carregandoHabilidades = true;
      const data = await this.$services.objetivosAprendizagem.pesquisar(
        this.search
      );
      this.objetivosAprendizagem = data.data.data;
      this.carregandoHabilidades = false;
    },
    async adicionarHabilidade(m) {
      const habilidade = this.objeto_aprendizagem.find((o) => o.id === m.id);
      if (habilidade) {
        this.mensagem = "Habilidade já selecionada!";
        this.showMessage = true;
        return;
      }
      this.objeto_aprendizagem.push(m);
      this.mensagem = "Habilidade adicionada com sucesso!";
      this.showMessage = true;

      this.adicionarExperiencia(m.parent.descricao);
    },
    async adicionarExperiencia(experiencia) {
      const experienciaJaExiste = this.experiencia.find(
        (e) => e === experiencia
      );
      if (!experienciaJaExiste) this.experiencia.push(experiencia);
    },
    async deletarHabilidade(index, m) {
      const habilidadesExperiencias = this.objeto_aprendizagem.filter(
        (o) => o.parent.descricao === m.parent.descricao
      );
      if (habilidadesExperiencias.length === 1) {
        this.deletarExperiencia(m.parent.descricao);
      }
      this.objeto_aprendizagem.splice(index, 1);
      this.mensagem = "Habilidade deletada com sucesso!";
      this.showMessage = true;
    },
    async deletarExperiencia(experiencia) {
      const pesquisa = experiencia.toString();
      const index = this.experiencia.findIndex((e) => e === pesquisa);
      this.experiencia.splice(index, 1);
      const habilidades = this.objeto_aprendizagem.filter(
        (o) => o.parent.descricao !== pesquisa
      );
      this.objeto_aprendizagem = habilidades;
    },
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { bimestre } = this.form;
        this.form.bimestre = bimestre;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.experiencia = this.experiencia.map((e) => e);
        this.form.objeto_aprendizagem = this.objeto_aprendizagem.map(
          (o) => o.id
        );
        await this.$services.planosBimestraisInfantilService.createOrUpdate(
          this.form
        );
        this.$toast.success("Plano bimestral salvo com sucesso!");
      } catch (error) {
        this.$toast.info(
          "Você está temporariamente com seu usuário bloqueado(a)."
        );
      }
      this.$loaderService.close();
    },
    remove(item) {
      const index = this.recursos.indexOf(item);
      if (index >= 0) {
        this.recursos.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.dataInput {
  background-color: white;
  width: 100%;
  height: 35px;
  box-shadow: 2px 2px rgb(0, 0, 0, 0.1);
}
.custom-chips {
  background-color: rgb(172, 6, 6);
  width: 100%;
  height: 35px;
  box-shadow: 2px 2px rgb(0, 0, 0, 0.1);
}
.pa-3 {
  padding: 16px;
}
.small-text {
  font-size: 1rem;
}
</style>
