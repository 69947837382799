<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Diário AEE </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn
            color="success"
            @click="criarGestaoDeAula"
            class="mr-2"
            v-show="$can(['gestoesDeAulas.criar', 'gestoesDeAulas.*'])"
            >Criar Vínculo AEE</v-btn
          >
          <v-spacer />
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="turmasList"
          :loading="carregandoTurmas"
          :items-per-page="10"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                :class="parseInt(turma.notifiq, 10) === 1 ? 'green lighten-5' : ''"
                v-for="turma of items"
                :key="turma.id"
              >
                <td>{{ turma.id }}</td>
                <td>
                  <v-btn text>
                    {{ turma.turma.descricao }}
                  </v-btn>
                  <v-chip
                    v-if="parseInt(turma.notifiq, 10) === 1"
                    label
                    v-bind="attrs"
                    v-on="on"
                    class="white--text"
                    color="green darken-4"
                    x-small
                  >
                    NOTIFIQ
                  </v-chip>
                </td>
                <td>{{ turma.disciplina.descricao }}</td>
                <td>{{ turma.professor.nome }}</td>
                <td class="text-center">
                  {{ turma.turma.matriculas_count }}/{{ turma.turma.qtd }}
                </td>

                <td width="7%">
                  <div class="text-center">
                    <v-menu :close-on-content-click="false" :nudge-width="100" offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small color="indigo" dark v-bind="attrs" v-on="on"> AÇÕES </v-btn>
                      </template>
                      <v-card class="mx-auto" max-width="430">
                        <v-list>
                          <v-list-item>
                            <v-list-item-avatar color="primary">
                              <v-list-item-title style="color: #fff">{{
                                turma.disciplina.descricao
                                  ? turma.disciplina.descricao.substring(0, 1).toUpperCase()
                                  : "- - -"
                              }}</v-list-item-title>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                turma.professor.nome ? turma.professor.nome : "- - -"
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >{{ turma.turma.descricao ? turma.turma.descricao : "- - -" }} /
                                {{
                                  turma.disciplina.descricao ? turma.disciplina.descricao : "- - -"
                                }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <!-- item -->
                        <v-list>
                          <v-list-item-group color="primary" v-if="!turma.estaDeletado">
                            <v-list-item @click="() => visualizarTurma(turma)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-book</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Educação Inclusiva</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <v-list-item-group color="primary" v-if="!turma.estaDeletado">
                            <v-list-item
                              :to="{
                                name: 'educacaoInclusiva.planosBimestralAEE',
                                params: { gestaoDeAulaId: turma.id },
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon small>fa fa-book</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Plano bimestral</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <v-list-item-group color="primary">
                            <v-menu open-on-hover left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-if="!turma.estaDeletado" v-bind="attrs" v-on="on">
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Gerar plano bimestral</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, index) in $constants.bimestres"
                                  :key="index"
                                >
                                  <v-list-item-title @click="gerarPlanoBimestral(turma.id, item)">
                                    {{ item.desc }}
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-group>

                          <v-list-item-group
                            color="primary"
                            v-if="
                              !turma.estaDeletado &&
                              $can(['gestoesDeAulas.atualizar', 'gestoesDeAulas.*'])
                            "
                          >
                            <v-list-item @click="() => editarGestaoDeAula(turma)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-edit</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editar gestão de aulas</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->
                          <!-- item -->
                          <v-list-item-group
                            color="primary"
                            v-if="
                              !turma.estaDeletado &&
                              $can(['gestoesDeAulas.atualizar', 'gestoesDeAulas.*'])
                            "
                          >
                            <v-list-item @click="() => deletarGestaoDeAula(turma)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-trash</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Apagar gestão de aulas </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          @changeActionResponse="listarTurmas"
          :search="search"
          :pageNumber="parseInt(page_number)"
        >
        </e-paginate>
      </v-col>
    </v-row>
    <e-selecionar-gestao-modal
      :dialog="dialog"
      :turma_id="turma_id"
      @dialogChange="dialog = $event"
    ></e-selecionar-gestao-modal>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Turma from "@/Models/Turma";
import GestaoDeAula from "@/Models/GestaoDeAula";

export default {
  data() {
    return {
      page_number: 0,
      carregandoTurmas: false,
      dialog: false,
      dataTableFilter,
      search: "",
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      turmasList: [],
      gestoesDeAulasList: [],
      turma_id: null,
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Turma", value: "turma" },
          { text: "Disciplina", value: "disciplina" },
          { text: "Professor", value: "professor" },
          { text: "Quantidade", value: "quantidade", sortable: false },
          { text: "", value: "actions", sortable: false, width: "200px" },
        ],
      },
    };
  },
  async mounted() {
    this.$loaderService.open("Carregando gestoesDeAulas");
    try {
      await this.listarTurmas(this.pageNumber);
    } catch (error) {
      this.$handleError(error);
    }
    this.$loaderService.close();
  },
  watch: {
    carregandoTurmas(value) {
      if (value && this.turmasList.length === 0) {
        this.$loaderService.open("Carregando turmas");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 1) {
        await this.listarTurmas(0, val);
      }
      if (!val) {
        await this.listarTurmas(0);
      }
    },
  },
  methods: {
    async listarTurmas(pageNumber, query = null) {
      this.carregandoTurmas = true;
      this.$loaderService.open("Carregando Turmas");
      try {
        const response = await this.$services.gestoesDeAulasAEEService.syncAll(pageNumber, query);
        this.turmasList = await response.data.map((gestao) => new GestaoDeAula(gestao));
        this.turmasList = this.turmasList.map((turma) => {
          return { ...turma, selected: false };
        });
        this.paginate = response;
      } catch (error) {
        this.$loaderService.close();
        this.$handleError(error);
      }
      this.carregandoTurmas = false;
      this.$loaderService.close();
    },
    visualizarTurma(turma) {
      const gestaoDeAulasId = turma.id;
      const turmaId = turma.turma.id;

      this.$router.push({
        name: "relatorios.visualizarAEE",
        params: {
          gestaoDeAulasId,
          turmaId,
        },
      });
    },
    criarGestaoDeAula() {
      this.$router.push({ name: "gestoesDeAulasAEE.create" });
    },
    editarGestaoDeAula(turma) {
      this.$router.push({
        name: "gestoesDeAulasAEE.edit",
        params: { gestaoDeAulasId: turma.id },
      });
    },
    async deletarGestaoDeAula(turma) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta gestaoDeAulas?",
        confirmationCode: turma.id,
        confirmationMessage: `Por favor, digite <strong>${turma.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a GestaoDeAula");
              try {
                await this.$services.gestoesDeAulasAEEService.deletarGestaoDeAula(turma);
                this.$toast.success("GestaoDeAula deletada com sucesso");
                window.location.reload();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },

    async gerarPlanoBimestral(gestaoId, item) {
      try {
        this.$loaderService.open("Gerando plano de aula...");
        await this.$services.relatoriosService.planoBimestralAEE(
          parseInt(gestaoId, 10),
          parseInt(item.value, 10)
        );
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
  },
};
</script>

<style></style>
