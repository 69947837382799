const defaultTheme = {
  primary: "#3F51B5",
  secondary: "#f03b00",
  accent: "#E86413",
  error: "#f44336",
  warning: "#ff9800",
  info: "#03a9f4",
  success: "#4caf50",
  anchor: "red",
  white: "#fff",
};
// #ededed
// #f5f5f5
export default defaultTheme;
