<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'educacaoInclusiva',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Bimestral AEE
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green lighten-1" outlined>
          <v-col cols="10">
            <v-card-text>
              <h4>Turma: {{ gestaoDeAula?.turma?.descricao }}</h4>
              <h4>Disciplina: {{ gestaoDeAula?.disciplina?.descricao }}</h4>
              <h4>
                Professor:
                {{ gestaoDeAula?.professor ? gestaoDeAula?.professor?.nome : "[carregando...]" }}
              </h4>
            </v-card-text>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  class="ml-1 white--text"
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => verHistoricoPlanos()"
                >
                  <span>Histórico do lancamento de planos</span>
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="12 text-center" class="pt-2">
        <template>
          <v-toolbar>
            <v-tabs dark v-model="form.bimestre" background-color="primary" grow>
              <v-tab
                v-for="item of $constants.bimestres.concat([
                  { value: 'descriptors', desc: 'DESCRITORES' },
                ])"
                :key="item.value"
                @click="handleTabClick(item)"
                :disabled="item.value === 'descriptors' && shouldDisableDescriptorsTab"
              >
                <v-badge v-if="form.bimestre === item.value" color="success">
                  {{ item.desc }}
                </v-badge>
                <div v-else>{{ item.desc }}</div>
              </v-tab>
            </v-tabs>
          </v-toolbar>
        </template>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row v-if="form.bimestre <= 3">
          <v-col cols="12">
            <ValidationProvider name="Tematica" rules="required" v-slot="{ errors }">
              <e-label>UNIDADES TEMÁTICAS / PRÁTICAS DE LINGUAGEM</e-label>
              <v-textarea solo dense v-model="form.tematica" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
              <e-label>OBJETOS DO CONHECIMENTO</e-label>
              <v-textarea solo dense v-model="form.objeto_conhecimento" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Habilidades" rules="required" v-slot="{ errors }">
              <e-label>HABILIDADES</e-label>
              <v-textarea solo dense v-model="form.habilidades" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
              <e-label>METODOLOGIA</e-label>
              <v-textarea solo dense v-model="form.metodologia" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Recursos didaticos" rules="required" v-slot="{ errors }">
              <e-label>RECURSOS DIDÁTICOS</e-label>
              <v-textarea solo dense v-model="form.recursos_didaticos" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              name="Avaliação da aprendizagem"
              rules="required"
              v-slot="{ errors }"
            >
              <e-label>AVALIAÇÃO DA APRENDIZAGEM</e-label>
              <v-textarea
                solo
                dense
                v-model="form.avaliacao_aprendizagem"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Referencia" rules="required" v-slot="{ errors }">
              <e-label>REFERÊNCIAS</e-label>
              <v-textarea solo dense v-model="form.referencias" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-col cols="12" v-if="form.bimestre >= 4">
          <v-dialog v-model="dialogs" fullscreen hide-overlay transition="dialog-bottom-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mb-3 mt-5" color="blue" dark v-bind="attrs" v-on="on">
                INSIRA O CÓDIGO
              </v-btn>
            </template>

            <v-card>
              <v-toolbar dark color="blue">
                <v-btn icon dark @click="dialogs = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>PESQUISA</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-sheet color="white" elevation="1">
                      <div class="py-2 px-4" color="white">
                        <v-text-field
                          @blur="search.length === 0 ? (objetivosAprendizagem = []) : false"
                          v-model="search"
                          autofocus
                          v-on:keyup="() => procurarHabilidade()"
                          label="DIGITE O CODIGO!"
                        ></v-text-field>
                      </div>
                    </v-sheet>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-sheet color="white" elevation="1" rounded>
                      <v-lazy>
                        <div class="text-start py-2 px-4 text-button blue white--text">
                          HABILIDADES ENCONTRADAS
                          <v-icon dark right> mdi-magnify </v-icon>
                        </div>
                      </v-lazy>
                      <v-divider class="theme--light"></v-divider>

                      <div class="py-2 px-4">
                        <v-skeleton-loader
                          v-if="carregandoHabilidades"
                          class="mx-auto"
                          max-width="auto"
                          type="list-item"
                        ></v-skeleton-loader>

                        <v-list-item
                          class="grey lighten-3 mb-3 pa-md-4"
                          v-for="m in objetivosAprendizagem"
                          :key="m.id"
                        >
                          <v-col cols="12" sm="10" class="d-flex flex-column justify-start">
                            [ {{ m.tipo }} ] {{ m.descricao }}
                            <v-row>
                              <v-col>
                                <v-chip class="mt-2" color="primary">
                                  {{ m.disciplina?.descricao ? m.disciplina?.descricao : "" }}
                                </v-chip>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="12" sm="2" align="center">
                            <v-btn
                              @click="() => adicionarHabilidade(m)"
                              class="mx-2"
                              fab
                              dark
                              small
                              color="blue"
                            >
                              <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                          </v-col>
                        </v-list-item>
                      </div>
                    </v-sheet>
                  </v-col>
                  <v-col cols="6">
                    <v-sheet color="white" elevation="1">
                      <v-lazy>
                        <div class="text-start py-2 px-4 text-button blue white--text">
                          Habilidades selecionados
                          <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                        </div>
                      </v-lazy>

                      <v-divider class="theme--light"></v-divider>

                      <div class="py-2 px-4">
                        <p v-if="objeto_aprendizagem.length === 0">
                          Nenhuma habilidade selecionada
                        </p>

                        <v-list-item
                          class="grey lighten-3 mb-3 pa-md-4"
                          v-for="(m, index) in objeto_aprendizagem"
                          :key="m.id"
                        >
                          <v-btn
                            @click="() => deletarHabilidade(index, m)"
                            class="mx-2"
                            fab
                            dark
                            small
                            color="red"
                          >
                            <v-icon dark> mdi-delete </v-icon>
                          </v-btn>

                          <v-col cols="12" sm="10" class="d-flex flex-column justify-start">
                            [ {{ m.tipo }} ]
                            {{ m.descricao }}
                            <v-row>
                              <v-col>
                                <v-chip class="mt-2" color="primary">
                                  {{ m.disciplina?.descricao ? m.disciplina?.descricao : "" }}
                                </v-chip>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-list-item>
                      </div>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>

            <v-snackbar v-model="showMessage" color="success">
              <h4>{{ mensagem }}</h4>
              <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="showMessage = false"> x </v-btn>
              </template>
            </v-snackbar>
          </v-dialog>

          <div></div>

          <label for=""> DESCRITORES SAEB/SAEPI</label>
          <v-card class="pa-md-4 mt-1">
            <div>
              <v-list>
                <v-divider class="theme--light"></v-divider>

                <v-list-item
                  class="grey lighten-3 mb-3 pa-md-4"
                  v-for="(m, index) in objeto_aprendizagem"
                  :key="m.id"
                >
                  <v-btn
                    @click="() => deletarHabilidade(index, m)"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="red"
                  >
                    <v-icon dark> mdi-delete </v-icon>
                  </v-btn>
                  <v-col cols="12" sm="10" class="d-flex flex-column justify-start">
                    [ {{ m.tipo }} ] {{ m.descricao }}
                    <v-row>
                      <v-col>
                        <v-chip class="mt-2" color="primary">
                          {{ m.disciplina?.descricao ? m.disciplina?.descricao : "" }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-list-item>
              </v-list>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
            <!-- <v-btn color="success" class="ml-3" @click="dialog = true">Exportar plano</v-btn> -->
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title class="primary">
            <span style="color: #fff" class="text-h5">Exportar Plano</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(exportarPlano)" :disabled="submittingForm">
                  <v-row>
                    <v-col class="pt-4 pb-0" cols="12">
                      <e-label class="font-weight-medium">Turma</e-label>
                      <p class="font-weight-regular">
                        <v-select
                          :items="turmas"
                          item-text="turma.descricao"
                          item-value="turma.id"
                          return-object
                          label="Selecione o campo abaixo"
                          v-model="turmaSelecionada"
                          @change="(item) => setGestao(item)"
                        ></v-select>
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <e-label class="font-weight-medium"
                        >UNIDADES TEMÁTICAS / PRÁTICAS DE LINGUAGEM</e-label
                      >
                      <p class="font-weight-regular">
                        {{ form.tematica }}
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <e-label class="font-weight-medium">Metodologia</e-label>
                      <p class="font-weight-regular">
                        {{ form.metodologia }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn style="color: #fff" class="red darken-1" @click="dialog = false">
                      Fechar
                    </v-btn>
                    <v-btn
                      :disabled="submittingForm"
                      :loading="submittingForm"
                      type="submit"
                      style="color: #fff"
                      class="green darken-1"
                      >Salvar</v-btn
                    >
                  </v-card-actions>
                </form>
              </ValidationObserver>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <e-modal-confirm
        :confirmDialog="confirmDialog"
        :objectModal="objectModal"
        @changeModalConfirm="enableSubmit"
      >
      </e-modal-confirm>
    </v-row>
    <e-drawer-planos-lancados
      v-model="openDrawer"
      :gestao_id="parseInt($route.params.gestaoDeAulaId, 10)"
      :bimestre="parseInt(form.bimestre, 10)"
    />
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  async mounted() {
    await this.loadData();
  },
  data() {
    return {
      submittingForm: false,
      enableSubmit: true,
      confirmDialog: false,
      turmaSelecionada: {
        turma: {
          id: null,
          franquia: {
            descricao: "",
            fantasia: "",
          },
        },
      },
      objectModal: {
        title: "Exportar plano",
        message: "Tem certeza que deseja exportar esta plano?",
      },
      form: {
        bimestre: 0,
      },
      formExportarPlano: {
        bimestre: 0,
      },
      openDrawer: false,
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      turmas: [],
      loadPlano: true,
      objeto_aprendizagem: [],
      objetivosAprendizagem: [],
      dialog: false,
      dialogs: false,
      dialogBNCC: false,
      carregandoHabilidades: false,
      showMessage: false,
      search: "",
      mensagem: "",
    };
  },
  computed: {
    shouldDisableDescriptorsTab() {
      const disciplinaId = this.gestaoDeAula?.disciplina?.id;
      const turma = this.gestaoDeAula?.turma;
      const serieId = turma && turma.series ? turma.series[0]?.pivot?.serie_id : null;
      return ![3, 6].includes(disciplinaId) || ![5, 8, 9, 12].includes(serieId);
    },
  },
  methods: {
    async handleTabClick(item) {
      if (item.value !== "descriptors") {
        await this.editarPlano(item.value ? item.value : 0);
      }
    },
    async verHistoricoPlanos() {
      this.openDrawer = !this.openDrawer;
    },
    async editarPlano(bimestre) {
      this.loadPlano = true;
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        await this.$services.planosBimestraisService.createOrUpdate(this.form);
        const response = await this.$services.planosBimestraisService.planoBimestral(
          gestaoDeAulaId,
          bimestre
        );
        if (response !== "") {
          this.form = response.plano;
          return;
        }
        this.form = {
          bimestre,
        };
        this.loadPlano = true;
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
    setGestao(item) {
      this.export_gestao = item.id;
    },
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { bimestre } = this.form;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.bimestre = bimestre;
        this.form.objeto_aprendizagem = this.objeto_aprendizagem.map((obj) => obj.id);
        await this.$services.planosBimestraisService.createOrUpdate(this.form);
        this.$toast.success("Plano bimestral salvo com sucesso!");
      } catch (error) {
        this.$toast.info("Você está temporariamente com seu usuário bloqueado(a).");
      }
      this.$loaderService.close();
    },
    async loadData() {
      const { gestaoDeAulaId } = this.$route.params;
      const { bimestre } = this.form;

      try {
        this.$loaderService.open("Carregando dados...");
        this.gestaoDeAula = await this.$services.gestoesDeAulasAEEService.getGestao(gestaoDeAulaId);

        // const gestoes = await this.$services.gestoesDeAulasAEEService.listarGestoesProfessor(
        //   this.gestaoDeAula
        // );
        // this.turmas = gestoes;

        const response = await this.$services.planosBimestraisService.planoBimestral(
          gestaoDeAulaId,
          0
        );
        if (response !== "") {
          this.form = response.plano;
          this.objeto_aprendizagem = [];

          response.habilidades_saeb.forEach((habilidade) => {
            if (habilidade.habilidade_saepi_saeb) {
              const item = habilidade.habilidade_saepi_saeb;
              this.objeto_aprendizagem.push(item);
            }
          });
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error.error);
        this.$router.push({ name: "educacaoInclusiva" });
      } finally {
        this.$loaderService.close();
      }
    },
    async exportarPlano() {
      this.$loaderService.open("Exportando plano...");
      try {
        // const { gestaoDeAulaId } = this.$route.params;
        const { bimestre } = this.form;

        this.formExportarPlano.instrutorDisciplinaTurma_id = this.export_gestao;
        this.formExportarPlano.bimestre = bimestre;
        this.formExportarPlano.tematica = this.form.tematica;
        this.formExportarPlano.objeto_conhecimento = this.form.objeto_conhecimento;
        this.formExportarPlano.habilidades = this.form.habilidades;
        this.formExportarPlano.metodologia = this.form.metodologia;
        this.formExportarPlano.recursos_didaticos = this.form.recursos_didaticos;

        // this.formExportarPlano.id = this.form.id;
        this.formExportarPlano.referencias = this.form.referencias;
        this.formExportarPlano.avaliacao_aprendizagem = this.form.avaliacao_aprendizagem;

        await this.$services.planosBimestraisService.createOrUpdate(this.formExportarPlano);

        this.$toast.success("Plano bimestral exportado com sucesso!");

        this.dialog = false;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async procurarHabilidade() {
      this.carregandoHabilidades = true;
      const { gestaoDeAula } = this;
      if (gestaoDeAula) {
        const { disciplina_id } = gestaoDeAula;
        const { turma_id } = gestaoDeAula;
        const data = await this.$services.objetivosAprendizagem.pesquisarSaeb({
          disciplina_id,
          turma_id,
          search: this.search,
        });
        this.objetivosAprendizagem = data.data.data;
      }
      this.carregandoHabilidades = false;
    },
    async adicionarHabilidade(m) {
      const habilidade = this.objeto_aprendizagem.find((o) => o.id === m.id);
      if (habilidade) {
        this.mensagem = "Habilidade já selecionada!";
        this.showMessage = true;
        return;
      }
      this.objeto_aprendizagem.push(m);
      this.mensagem = "Habilidade adicionada com sucesso!";
      this.showMessage = true;
    },
    async deletarHabilidade(index, m) {
      const habilidadesExperiencias = this.objeto_aprendizagem.filter((o) => o.id === m.id);
      this.objeto_aprendizagem.splice(index, 1);
      this.mensagem = "Habilidade deletada com sucesso!";
      this.showMessage = true;
    },
  },
};
</script>

<style></style>
