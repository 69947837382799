<template>
  <div>
    <span v-if="!anosLoaded || !anoSelecionado || !user">Carregando anos..</span>
    <v-menu v-else offset-y rounded>
      <template v-slot:activator="{ on, attrs }">
        <v-chip color="primary" label x-small link v-bind="attrs" v-on="on">
          {{ anoSelecionado.descricao }}
          <v-icon right x-small> mdi-pencil-box </v-icon>
        </v-chip>
        <v-progress-circular
          indeterminate
          color="primary"
          class="ml-2"
          :width="2"
          v-show="mudandoAno"
          :size="15"
        ></v-progress-circular>
      </template>
      <v-list>
        <v-list-item-group :value="anosList.indexOf(anoSelecionado)" color="primary">
          <v-list-item v-for="ano of anosList" :key="ano.id" active @click="selecionarAno(ano)">
            <v-list-item-title>{{ ano.descricao }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EAnoSelector",
  data() {
    return {
      anosList: [],
      anosLoaded: false,
      mudandoAno: false,
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    anoSelecionado() {
      if (!this.user || !this.anosList || !this.user.ano) return null;
      const anosComIdIgual = this.anosList.filter((ano) => ano.id === this.user.ano.id);
      if (anosComIdIgual.length === 0) return null;
      return anosComIdIgual[0];
    },
  },
  created() {
    this.loadAnos();
  },
  methods: {
    async loadAnos() {
      this.anosList = await this.$services.anosService.syncAll();
      this.anosLoaded = true;
    },
    async selecionarAno(ano) {
      if (ano.id === this.user.ano.id) return;
      this.mudandoAno = true;
      try {
        await this.$services.authService.mudarDeAno(ano);
        this.$toast.success("Ano selecionado com sucesso");
      } catch (error) {
        this.$handleError(error);
      }
      this.mudandoAno = false;
    },
  },
};
</script>

<style scoped>
.v-list-item__title {
  font-size: 0.8rem;
  padding: 0.2em;
}
</style>
