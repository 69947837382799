import NewEditEducacaoInclusivaPage from "@/Pages/EducacaoInclusiva/NewEditEducacaoInclusivaPage.vue";
import DiarioAEEIndexPage from "@/Pages/EducacaoInclusiva/index.vue";
import PlanoBimestralDiarioAEE from "@/Pages/EducacaoInclusiva/PlanoBimestralAEE.vue";

export default [
  {
    path: "/educacao-inclusiva/",
    name: "educacaoInclusiva",
    component: DiarioAEEIndexPage,
  },
  {
    path: "/educacao-inclusiva/plano-bimestral/:gestaoDeAulaId",
    name: "educacaoInclusiva.planosBimestralAEE",
    component: PlanoBimestralDiarioAEE,
  },
  {
    path: "/educacao-inclusiva/create/:matricula_id",
    name: "educacaoInclusiva.create",
    component: NewEditEducacaoInclusivaPage,
    props: { editing: false },
  },
  {
    path: "/educacao-inclusiva/edit/:matricula_id",
    name: "educacaoInclusiva.edit",
    component: NewEditEducacaoInclusivaPage,
    props: { editing: true },
  },
];
