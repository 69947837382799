<template>
  <main-template>
    <div style="padding-bottom: 5%">
      <!-- <div v-if="user.roleName == 'super-admin' || user.roleName == 'admin'"> -->
      <div>
        <!-- <v-col class="">
        <v-card outlined class="mx-auto mt-2">
          <apexchart
            type="line"
            height="400"
            :options="options"
            :series="series"
          ></apexchart>
        </v-card>
      </v-col> -->

        <v-row>
          <v-card-title class="font-weight-black">Painel de indicadores</v-card-title>
        </v-row>

        <v-row>
          <e-unidades-card />
          <e-turmas-card />
          <e-matriculas-card />
          <e-taxa-ocupacao-card />
        </v-row>

        <v-row>
          <v-card-title class="font-weight-black">Alunos</v-card-title>
        </v-row>

        <v-row>
          <e-faixa-etaria-card />
          <e-zona-card />
          <e-genero-card />
          <e-deficientes-card />
        </v-row>

        <v-row>
          <v-card-title class="font-weight-black">Professores</v-card-title>
        </v-row>

        <v-row>
          <e-quantidade-professores-card />
          <e-professor-vinculo-card />
          <e-professor-genero-card />
          <e-professor-deficientes-card />
        </v-row>
      </div>

      <v-row style="margin-bottom: -30px">
        <v-col cols="12 mt-4">
          <e-label>Pesquise uma unidade escolar aqui</e-label>
          <e-autocomplete
            :items="franquiasListInfo"
            :return-object="false"
            :item-value="(value) => value"
            :item-text="(value) => value.descricao"
            v-model="franquiaSelecionada"
            label="Selecione uma opção"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-card-title class="font-weight-black">Resumo unidade</v-card-title>
      </v-row>

      <!--
      <v-row>
        <v-card-title>Resumo unidade</v-card-title>
      </v-row>
    -->

      <v-row class="mt-2">
        <e-matriculas-unidade-card :franquiaId="franquiaId" />
        <e-aulas-lancadas-card :franquiaId="franquiaId" />
        <e-aulas-confirmadas-lancadas-card :franquiaId="franquiaId" />
        <e-taxa-evasao-card :franquiaId="franquiaId" />
      </v-row>

      <v-row>
        <!-- <v-col>
          <v-progress-linear v-model="power" color="red" height="25"></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear v-model="power" color="amber" height="25"></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear v-model="power" color="green" height="25"></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear v-model="power" color="blue" height="25"></v-progress-linear>
        </v-col> -->
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card outlined class="mx-auto mt-2">
            <apexchart type="line" height="400" :options="options" :series="series"></apexchart>
          </v-card>
        </v-col>
        <!-- <v-col
          v-if="
            user.roles.filter((role) => role.name == 'super-admin' || role.name == 'admin')
              .length >= 1
          "
          cols="12"
        >
          <v-card outlined class="mx-auto mt-2">
            <apexchart
              type="line"
              height="400"
              :options="aulasMunicipio"
              :series="dadosMunicipio"
            ></apexchart>
          </v-card>
        </v-col> -->
      </v-row>
    </div>
  </main-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "HomePage",
  async created() {
    await this.$services.franquiasService.syncAll();
    await this.loadData();
    this.franquiaId = this.user.franquia.id;
    await this.graficoDeAulasPorEscola();
    await this.graficoDeAulasDoMunicipio();
  },
  computed: {
    ...mapGetters("Auth", ["senhaFraca", "user"]),
    ...mapGetters("Franquias", ["carregandoFranquias"]),
  },
  watch: {
    user() {
      this.franquiaId = this.user.franquia.id;
      this.graficoDeAulasPorEscola();
    },
    franquiaSelecionada(value) {
      if (value) {
        this.$services.authService.mudarDeFranquia(this.franquiaSelecionada).then(() => {
          this.$toast.success("Unidade escolar alterada com sucesso!");
        });
      }
    },
    max(value) {
      this.options = {
        chart: {
          foreColor: "#263238",
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
        },
        stroke: {
          width: [0, 4],
        },
        plotOptions: {
          bar: {
            // horizontal: true,
            columnWidth: "35%",
            endingShape: "rounded",
          },
        },
        colors: ["#0d6efd", "#212529"],
        title: {
          text: "Gráfico de aulas da escola atual",
          style: {
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [
          "Jan",
          "Fev",
          "mar",
          "abr",
          "mai",
          "jun",
          "jul",
          "ago",
          "set",
          "out",
          "nov",
          "dez",
        ],
        xaxis: {
          // type: "datetime",
        },
        yaxis: [
          {
            title: {
              text: "Lançadas",
            },
            max: value,
          },
          {
            opposite: true,
            title: {
              text: "Confirmadas",
            },
            max: value,
            logarithmic: false,
            logBase: 10,
            floating: false,
          },
        ],
      };
    },
    maxMunicipio(value) {
      this.aulasMunicipio = {
        chart: {
          foreColor: "#263238",
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
        },
        stroke: {
          width: [0, 4],
        },
        plotOptions: {
          bar: {
            // horizontal: true,
            columnWidth: "35%",
            endingShape: "rounded",
          },
        },
        colors: ["#0d6efd", "#212529"],
        title: {
          text: "Gráfico de aulas de todas as escolas do município",
          style: {
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [
          "Jan",
          "Fev",
          "mar",
          "abr",
          "mai",
          "jun",
          "jul",
          "ago",
          "set",
          "out",
          "nov",
          "dez",
        ],
        xaxis: {
          // type: "datetime",
        },
        yaxis: [
          {
            title: {
              text: "Lançadas",
            },
            max: value,
          },
          {
            opposite: true,
            title: {
              text: "Confirmadas",
            },
            max: value,
            logarithmic: false,
            logBase: 10,
            floating: false,
          },
        ],
      };
    },
  },
  data() {
    return {
      power: 30,
      franquiaSelecionada: null,
      query: "",
      franquiaId: null,
      openSelecionarFranquiaModal: false,
      franquiasListInfo: [],
      // aqui
      labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
      value: [200, 675, 410, 390, 310, 460, 250, 240],
      max: 100,
      maxMunicipio: 100,
      aulasAguardandoConfirmacaoCount: null,
      carregandoTurmas: false,
      // aqui
      options: {
        chart: {
          foreColor: "#263238",
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
        },
        stroke: {
          width: [0, 4],
        },
        plotOptions: {
          bar: {
            // horizontal: true,
            columnWidth: "35%",
            endingShape: "rounded",
          },
        },
        colors: ["#0d6efd", "#212529"],
        title: {
          text: "Grafico de notas da escola atual",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [
          "Jan",
          "Fev",
          "mar",
          "abr",
          "mai",
          "jun",
          "jul",
          "ago",
          "set",
          "out",
          "nov",
          "dez",
        ],
        xaxis: {
          // type: "datetime",
        },
        yaxis: [
          {
            title: {
              text: "Lançadas",
            },
            max: 500,
          },
          {
            opposite: true,
            title: {
              text: "Confirmadas",
            },
            max: 500,
            logarithmic: false,
            logBase: 10,
            floating: false,
          },
        ],
      },
      aulasMunicipio: {
        chart: {
          foreColor: "#263238",
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
        },
        stroke: {
          width: [0, 4],
        },
        plotOptions: {
          bar: {
            // horizontal: true,
            columnWidth: "35%",
            endingShape: "rounded",
          },
        },
        colors: ["#0d6efd", "#212529"],
        title: {
          text: "Grafico de notas da escola atual",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [
          "Jan",
          "Fev",
          "mar",
          "abr",
          "mai",
          "jun",
          "jul",
          "ago",
          "set",
          "out",
          "nov",
          "dez",
        ],
        xaxis: {
          // type: "datetime",
        },
        yaxis: [
          {
            title: {
              text: "Lançadas",
            },
            max: 500,
          },
          {
            opposite: true,
            title: {
              text: "Confirmadas",
            },
            max: 500,
            logarithmic: false,
            logBase: 10,
            floating: false,
          },
        ],
      },
      series: [
        {
          name: "Aulas lançadas",
          type: "column",
          data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        },
        {
          name: "Aulas confirmadas",
          type: "line",
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
        },
      ],
      dadosMunicipio: [
        {
          name: "Aulas lançadas",
          type: "column",
          data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        },
        {
          name: "Aulas confirmadas",
          type: "line",
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
        },
      ],
    };
  },
  methods: {
    ...mapActions("Franquias", ["loadInfoFranquias"]),
    async loadData() {
      this.$loaderService.open("Carregando dados");
      try {
        this.franquiasListInfo = await this.$services.franquiasService.countInfoHome();
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
        this.$forceUpdate();
      }
    },
    async graficoDeAulasPorEscola() {
      const response = await this.$services.aulasService.graficoDeAulasLancadasEConfirmadas(
        this.franquiaId
      );
      if (response.data.length !== 0) {
        this.series = [];
        this.series.push({
          name: "Aulas lançadas",
          type: "column",
          data: [...response.data.aulasLancadas.map((a) => a.total)],
        });
        this.series.push({
          name: "Aulas confirmadas",
          type: "line",
          data: [...response.data.aulasConfirmadas.map((a) => a.total)],
        });
        const maiorNumero = Math.max(...response.data.aulasLancadas.map((a) => a.total));
        this.max = maiorNumero;
      }
    },
    async graficoDeAulasDoMunicipio() {
      const response =
        await this.$services.aulasService.graficoDeAulasLancadasEConfirmadasDoMunicipio();
      if (response.data.length !== 0) {
        this.dadosMunicipio = [];
        this.dadosMunicipio.push({
          name: "Aulas lançadas",
          type: "column",
          data: [...response.data.aulasLancadas.map((a) => a.total)],
        });
        this.dadosMunicipio.push({
          name: "Aulas confirmadas",
          type: "line",
          data: [...response.data.aulasConfirmadas.map((a) => a.total)],
        });
        const maiorNumero = Math.max(...response.data.aulasLancadas.map((a) => a.total));
        this.maxMunicipio = maiorNumero;
      }
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<!-- teste  -->
