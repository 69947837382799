import RelatorioListarTurmaPage from "@/Pages/Relatorios/ListarTurmaPage.vue";
import lancarRelatorioBNCCPage from "@/Pages/Relatorios/lancarRelatorioBNCCPage.vue";
import relatorioPage from "@/Pages/Relatorios/index.vue";
import RelatorioListarTurmaPageAEE from "@/Pages/Relatorios/ListarTurmaPageAEE.vue";

export default [
  {
    path: "/relatorios/:franquiaId",
    name: "relatorios",
    component: relatorioPage,
  },
  {
    path: "/relatorios/visualizar/:gestaoDeAulasId/:turmaId",
    name: "relatorios.visualizar",
    component: RelatorioListarTurmaPage,
  },
  {
    path: "/relatorios/lancarRelatorioBNCC/:matricula_id",
    name: "relatorios.lancarRelatorioBNCC",
    component: lancarRelatorioBNCCPage,
  },
  {
    path: "/relatorios/visualizarAEE/:gestaoDeAulasId/:turmaId",
    name: "relatorios.visualizarAEE",
    component: RelatorioListarTurmaPageAEE,
  },
];
