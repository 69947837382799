<template>
  <div class="d-flex align-center">
    <template
      v-if="
        $constants.situacoesMatriculaCondition[
          removerEspaco(matricula.situacao ? matricula.situacao : '')
        ]
      "
    >
      <v-currency-field
        :default-value="null"
        :value="value || null"
        hide-details
        :allow-negative="false"
        dense
        :min="min"
        :decimal-length="1"
        :max="max"
        ref="input"
        v-bind="{ outlined: true }"
        v-bind:style="{
          'min-width': '60px !important',
          'max-width': '60px !important',
        }"
        :disabled="true"
      />
    </template>
    <template v-else>
      <v-currency-field
        :default-value="null"
        @input="(val) => handleInput(val)"
        :value="value || null"
        hide-details
        :allow-negative="false"
        dense
        :min="min"
        :decimal-length="1"
        :max="max"
        ref="input"
        v-bind="{ outlined: true }"
        v-bind:style="{ 'min-width': '60px !important', 'max-width': '60px !important' }"
        :disabled="disabled"
      />
      <v-btn
        icon
        small
        v-show="value !== null && value !== undefined && !disabled"
        color="error"
        class="red lighten-5 ml-2"
        @click="emitNull"
      >
        <v-icon x-small>fa fa-close</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    matricula: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleInput(val) {
      if (val > this.max) {
        this.$refs.input.$emit("input");
        // this.$refs.input.$emit("input", this.max);
      } else if (val < this.min) {
        this.$refs.input.$emit("input", this.min);
      } else {
        this.$emit("input", parseFloat(val, 10));
        this.$emit("change", parseFloat(val, 10));
      }
    },
    emitNull() {
      this.$emit("input", undefined);
      this.$emit("change", undefined);
      setTimeout(() => {
        // eslint-disable-next-line
        this.$refs.input._data.formattedValue = "";
      }, 150);
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
  },
};
</script>
