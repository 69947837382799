import TurmasIndexPage from "@/Pages/Turmas/index.vue";
import NewEditTurmaPage from "@/Pages/Turmas/NewEditTurmaPage.vue";
import TurmasViewPage from "@/Pages/Turmas/view.vue";
import TurmasDashboard from "@/Pages/Turmas/dashboard.vue";
import TurmasViewPageAEE from "@/Pages/Turmas/viewAEE.vue";

export default [
  {
    path: "/turmas/",
    name: "turmas",
    component: TurmasIndexPage,
  },
  {
    path: "/turmas/create",
    name: "turmas.create",
    component: NewEditTurmaPage,
    props: { editing: false },
  },
  {
    path: "/turmas/edit/:turma_id",
    name: "turmas.edit",
    component: NewEditTurmaPage,
    props: { editing: true },
  },
  {
    path: "/turmas/view/:turma_id",
    name: "turmas.view",
    component: TurmasViewPage,
  },
  {
    path: "/turmas/:id/metricas",
    name: "turmas.dashboard",
    component: TurmasDashboard,
  },
  {
    path: "/turmas/viewAEE/:turma_id",
    name: "turmas.viewAEE",
    component: TurmasViewPageAEE,
  },
];
