<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Anual
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green lighten-1" outlined>
          <v-col cols="10">
            <v-card-text>
              <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
              <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
              <h4>
                Professor:
                {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
              </h4>
            </v-card-text>
          </v-col>
          <!-- Serie: {{ gestaoDeAula.turma.series[0].id }} -->
          <!-- Disciplina: {{ gestaoDeAula.disciplina.id }} -->
          <v-col cols="12">
            <v-row>
              <v-btn
                x-small
                color="accent"
                class="ml-7 fa-solid fa-file-import"
                @click="importarPlanoAnualdialog = true"
              >
                <v-icon left> fa-solid fa-file-import </v-icon>
                importar plano
              </v-btn>
            </v-row>
          </v-col>
        </v-card>
        <!-- quero listar no v-select apenas as gestoes que forem iguais a gestaoDeAula.turma.series[0].id e gestaoDeAula.disciplina.id.   -->
      </v-col>
      <v-col cols="12" class="pt-2">
        <template>
          <v-toolbar background-color="primary" color="success" grow>
            <v-tab style="text-align: center">Plano Anual</v-tab>
          </v-toolbar>
        </template>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row>
          <v-col cols="12">
            <ValidationProvider name="Competências" rules="required" v-slot="{ errors }">
              <e-label>COMPETÊNCIAS ESPECÍFICAS DA DISCIPLINA PARA O ENSINO FUNDAMENTAL</e-label>
              <v-textarea
                solo
                dense
                v-model="form.competencia_disciplina_ensino_fundamental"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Ementa" rules="required" v-slot="{ errors }">
              <e-label>EMENTA</e-label>
              <v-textarea solo dense v-model="form.ementa" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Unidades Temáticas" rules="required" v-slot="{ errors }">
              <e-label>UNIDADES TEMÁTICAS</e-label>
              <v-textarea solo dense v-model="form.unidades_tematicas" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Objetos de Conhecimento" rules="required" v-slot="{ errors }">
              <e-label>OBJETOS DE CONHECIMENTO</e-label>
              <v-textarea solo dense v-model="form.objetos_conhecimento" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Habilidades" rules="required" v-slot="{ errors }">
              <e-label>HABILIDADES</e-label>
              <v-textarea solo dense v-model="form.habilidades" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Descritores" rules="required" v-slot="{ errors }">
              <e-label>DESCRITORES SAEB / SAEPI</e-label>
              <v-textarea solo dense v-model="form.descritores" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
              <e-label>METODOLOGIA</e-label>
              <v-textarea solo dense v-model="form.metodologia" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Recursos didáticos" rules="required" v-slot="{ errors }">
              <e-label>RECURSOS DIDÁTICOS</e-label>
              <v-textarea solo dense v-model="form.recursos_didaticos" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Avaliação" rules="required" v-slot="{ errors }">
              <e-label>AVALIAÇÃO</e-label>
              <v-textarea solo dense v-model="form.avaliacao" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Referências" rules="required" v-slot="{ errors }">
              <e-label>REFERÊNCIAS</e-label>
              <v-textarea solo dense v-model="form.referencias" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
    <v-row justify="center">
      <v-dialog v-model="importarPlanoAnualdialog" persistent max-width="800px">
        <v-card>
          <v-card-title class="accent">
            <span style="color: #fff" class="text-h5">IMPORTAR PLANO ANUAL</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(importarPlano)" :disabled="submittingForm">
                  <v-row>
                    <v-col class="pt-4 pb-0" cols="12">
                      <e-label class="font-weight-medium">
                        Professora:
                        {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]"
                        }}<br />
                        Disciplina: {{ gestaoDeAula.disciplina.descricao }}
                      </e-label>
                      <p class="font-weight-regular">
                        <v-select
                          :items="filteredGestoes"
                          item-text="displayText"
                          item-value="id"
                          return-object
                          label="Selecione o plano abaixo"
                          v-model="planoSelecionado"
                        ></v-select>
                      </p>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      style="color: #fff"
                      class="red darken-1"
                      @click="importarPlanoAnualdialog = false"
                    >
                      Fechar
                    </v-btn>
                    <v-btn
                      :disabled="submittingForm"
                      :loading="submittingForm"
                      type="submit"
                      style="color: #fff"
                      class="green darken-1"
                      @click="preencherCampos(planoSelecionado)"
                    >
                      IMPORTAR
                    </v-btn>
                  </v-card-actions>
                </form>
              </ValidationObserver>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <e-modal-confirm
        :confirmDialog="importarPlanoconfirmDialog"
        :objectModal="objectModal"
        @changeModalConfirm="importarPlanoenableSubmit"
      >
      </e-modal-confirm>
    </v-row>
    <e-drawer-planos-lancados
      v-model="openDrawer"
      :gestao_id="parseInt($route.params.gestaoDeAulaId, 10)"
      :bimestre="parseInt(form.bimestre, 10)"
    />
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  watch: {
    "gestaoDeAula.turma.series": {
      handler() {
        // console.log('Séries da turma atualizadas:', this.gestaoDeAula.turma.series);
      },
      deep: true,
    },
    planos: {
      handler() {
        // console.log('Planos atualizados:', this.planos);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadData();
  },

  data() {
    return {
      submittingForm: false,
      form: {},
      openDrawer: false,
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      loadPlano: true,
      importarPlanoAnualdialog: false,
      importarPlanoconfirmDialog: false,
      objectModal: {
        title: "Importar plano",
        message:
          "Tem certeza de que deseja importar este plano? Todos os campos preenchidos serão perdidos.",
      },
      importarPlanoenableSubmit: true,
      turmaSelecionada: {
        turma: {
          id: null,
          franquia: {
            descricao: "",
            fantasia: "",
          },
        },
      },
      turmas: [],
      gestoesDoProfessor: [],
      planoSelecionado: null,
      planos: [],
    };
  },
  computed: {
    filteredGestoes() {
      const serieIds = this.gestaoDeAula.turma.series.map((serie) => serie.id);
      const disciplinaId = this.gestaoDeAula.disciplina.id;
      const filtered = this.planos.filter((plano) => {
        const isSerieMatch = serieIds.includes(Number(plano.serie_id));
        const isDisciplinaMatch = Number(plano.disciplina_id) === Number(disciplinaId);
        return isSerieMatch && isDisciplinaMatch;
      });
      return filtered.map((plano) => ({
        ...plano,
        displayText: `${plano.turma_descricao || "Sem Turma"} - ${
          plano.escola_descricao || "Sem Escola"
        }`,
      }));
    },
  },
  methods: {
    async verHistoricoPlanos() {
      this.openDrawer = !this.openDrawer;
    },

    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        await this.$services.planosAnuaisService.createOrUpdate(this.form);
        this.$toast.success("Plano anual salvo com sucesso!");
      } catch (error) {
        this.$toast.danger("Infelizmente ocorreu um erro ao salvar o plano anual.");
      } finally {
        this.$loaderService.close();
      }
    },

    async loadData() {
      this.$loaderService.open("Carregando dados...");
      const { gestaoDeAulaId } = this.$route.params;
      try {
        this.$loaderService.open("Carregando dados...");
        this.gestaoDeAula = await this.$services.gestoesDeAulasService.visualizar(gestaoDeAulaId);
        const gestoesDoProfessor = this.gestaoDeAula.instrutor_id;
        this.planos = await this.$services.gestoesDeAulasService.listarGestoesDoProfessor(
          gestoesDoProfessor
        );
        const response = await this.$services.planosAnuaisService.planoAnual(gestaoDeAulaId);

        if (response !== "") {
          this.form = response;
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async preencherCampos(plano) {
      if (plano) {
        this.form = { ...plano };
        await this.$nextTick();
        this.form.competencia_disciplina_ensino_fundamental =
          plano.competencia_disciplina_ensino_fundamental || "";
        this.form.ementa = plano.ementa || "";
        this.form.unidades_tematicas = plano.unidades_tematicas || "";
        this.form.objetos_conhecimento = plano.objetos_conhecimento || "";
        this.form.habilidades = plano.habilidades || "";
        this.form.descritores = plano.descritores || "";
        this.form.metodologia = plano.metodologia || "";
        this.form.recursos_didaticos = plano.recursos_didaticos || "";
        this.form.avaliacao = plano.avaliacao || "";
        this.form.referencias = plano.referencias || "";
      }
    },
    async importarPlano() {
      this.preencherCampos(this.planoSelecionado);
      if (!this.planoSelecionado) {
        this.$toast.danger("Nenhum plano selecionado. Por favor, selecione um plano.");
        return;
      }
      this.$toast.success("Plano anual importado com sucesso!");
      this.importarPlanoAnualdialog = false;
    },
  },
};
</script>

<style></style>
