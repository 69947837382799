import NewEditGestaoDeAulaAEEPage from "@/Pages/GestaoDeAulasAEE/NewEditGestaoDeAulaAEEPage.vue";

export default [
  {
    path: "/gestoes-de-aulas-aee/create",
    name: "gestoesDeAulasAEE.create",
    component: NewEditGestaoDeAulaAEEPage,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas-aee/edit/:gestaoDeAulasId",
    name: "gestoesDeAulasAEE.edit",
    component: NewEditGestaoDeAulaAEEPage,
    props: { editing: true },
  },
];
