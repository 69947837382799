import Axios from "@/plugins/Axios";
import store from "@/store";
import Professor from "@/Models/Professor";

export class ProfessoresService {
  async syncAll() {
    const response = await Axios().get("professores");
    const professores = response.data.map((professor) => new Professor(professor));
    // store.commit("Professores/setProfessores", professores);
    return professores;
  }

  async syncAllPaginate(pageNumber = 0, query = "") {
    const response = await Axios().get(`professores/paginate?page=${pageNumber}&query=${query}`);
    return response;
  }

  async criarProfessor(professor) {
    const response = await Axios().post("professores", professor);
    const novaProfessor = response.data;
    // store.commit("Professores/adicionarProfessor", novaProfessor);
    return novaProfessor;
  }

  async atualizarProfessor(professor) {
    const response = await Axios().put(`professores/${professor.id}`, professor);
    const professorAtualizada = response.data;
    // store.commit("Professores/atualizarProfessor", professorAtualizada);
    return professorAtualizada;
  }

  async deletarProfessor(professor) {
    const response = await Axios().delete(`professores/${professor.id}`);
    const professorDeletada = response.data;
    // store.commit("Professores/deletarProfessor", professor.id);
    return professorDeletada;
  }

  async getProfessor(professor_id) {
    const response = await Axios().get(`professores/${professor_id}`);
    return response.data;
    // store.commit("Professores/deletarProfessor", professor.id);
    // return professorDeletada;
  }

  async recuperarProfessor(professor) {
    return this.atualizarProfessor({ ...professor, deleted_at: null });
  }

  async inscricao(professor) {
    const response = await Axios().post("professores/i-congresso/inscricao", professor);
    return response;
  }

  async verificarInscricao() {
    const response = await Axios().get("professores/i-congresso/verificar-inscricao");
    return response.data;
  }

  async deletarInscricao(inscricao) {
    const response = await Axios().delete(
      `professores/i-congresso/deletar-inscricao/${inscricao.id}`
    );
    const professorDeletada = response.data;
    return professorDeletada;
  }

  async qtd() {
    const response = await Axios().get("professores/qtd");
    return response.data;
  }

  async generos() {
    const response = await Axios().get("professores/genero");
    return response.data;
  }

  async deficientes() {
    const response = await Axios().get("professores/deficientes");
    return response.data;
  }

  async tipos() {
    const response = await Axios().get("professores/tipos");
    return response.data;
  }

  async vinculos() {
    const response = await Axios().get("professores/vinculos");
    return response.data;
  }

  async grausAcademicos() {
    const response = await Axios().get("professores/graus-academicos");
    return response.data;
  }
}
export default new ProfessoresService();
